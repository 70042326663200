import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Image,
  Text,
  Input,
  Button,
} from "@chakra-ui/react";
import Select from "react-select";
import { Element } from "react-scroll";
import { toast } from "react-toastify";
import { fetchPostApi } from "../../utils/backendAPI";
import t1 from "../../assets/lottie/cubus.json";
import Lottie from "react-lottie";
const options = [
  { value: "legalconsulting", label: "Legal Consulting" },
  { value: "strategy", label: "Strategy" },
  { value: "gamification", label: "Gamification" },
  { value: "tokenomics", label: "Tokenomics" },
  { value: "smartcontract", label: "Smart Contracts" },
  { value: "web3", label: "Web 3" },
  { value: "design", label: "Design" },
  { value: "all", label: "All of the Above" },
];

export default function ContactUs() {
  const [name, setName] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [services, setServices] = useState<any>(null);
  const [message, setMessage] = useState<any>("");

  const notify = () => toast("Thankyou for contacting us!");

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendForm = async () => {
    if (
      name === "" ||
      email === "" ||
      services === null ||
      message === "" ||
      !validateEmail(email)
    ) {
      toast("Please fill all form field");
      return;
    }

    let msg = {
      name: name,
      email: email,
      services: services.value,
      message: message,
    };
    // Send data to the backend via POST
    await fetchPostApi("api/blockgurus/contactus", msg);
    notify();
    setName("");
    setEmail("");
    setMessage("");
    setServices(null);
  };
  const top = {
    loop: true,
    autoplay: true,
    animationData: t1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Element name="Contactus">
        <Box className="cntctus_main_bx">
          <Container>
            <Box className="cntctus_innr_bx">
              <Box className="cntctus_lotti_box">
                <Lottie options={top}></Lottie>
              </Box>
              <Box className="hdng_cntnt">
                <Heading as="h3">
                  <span>Contact </span> US
                </Heading>
                <Text>Schedule a Consultation</Text>
              </Box>
              <Box className="inpts_box">
                <Box className="lft_inpt">
                  <Box className="same_inpt">
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Your name"
                      type="text"
                    />
                  </Box>
                  <Box className="same_inpt">
                    <Input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your email"
                      type="text"
                    />
                  </Box>
                  <Box className="select_wrap">
                    <Select
                      value={services}
                      onChange={setServices}
                      placeholder="Choose services"
                      options={options}
                    />
                  </Box>
                </Box>
                <Box className="rght_inpt">
                  <Box className="same_inpt">
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="Message"
                      className="txt_area"
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="send_btn_bx">
                <Button className="send_btn" onClick={sendForm}>
                  SEND
                </Button>
              </Box>
              <Image
                src="/img/cntctus_logo.png"
                alt=""
                className="cntctus_logo"
              />
            </Box>
            <Box className="video_box_main">
              <video width="450px" height="450px" autoPlay muted loop>
                <source src="/img/vdcube_v6_cam3.webm" type="video/mp4" />
              </video>
            </Box>
          </Container>
          <Image src="/img/left_img_aj.png" alt="" className="left_img_aj" />
        </Box>
      </Element>
    </>
  );
}
