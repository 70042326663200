import React from 'react'
import {
    Box,
    Button,
    Container,
    Heading,
    Image,
    Text
  } from "@chakra-ui/react";
  import { Link } from "react-scroll";
  
export default function GoItAloneSec() {
  return (
    <>
        <Box className="goit_alone_main_bx">
            <Container>
                <Box className="two_boxes_bx">
                    <Box className="dont_goit_bx">
                        <Heading as="h4">Expert Support for Your Blockchain Journey</Heading>
                        <Text>You don't need to venture into the world of blockchain alone, potentially getting blindsided by the unexpected. We've been down this road and are prepared to guide you towards your blockchain goals.</Text>
                        <Text>Our seasoned legal team boasts over 20 years of experience collaborating with institutions like the IRS, the OCC, and the FDIC. Their comprehensive understanding of Banking Laws and Regulations will support you in remaining compliant while crafting strategies for success.</Text>
                        <Button className="def_btn">
                          <Link 
                            to="Contactus" 
                            activeClass='active'
                            spy={true}
                            smooth={true}
                            offset={360}
                            duration={500}
                            >
                              Contact Us
                            </Link>
                          </Button>
                        <Image src="/img/goit_alone_frame.png" alt='' className="img_frame" />
                    </Box>
                    <Image src="/img/dontgoit_blue_img.png" alt='' className="blue_img" />
                </Box>
            </Container>
        </Box>
    </>
  )
}
