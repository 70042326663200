import React, { useRef, useState } from "react";
import { Box, Button, Container, Heading, Image } from "@chakra-ui/react";
import { Link } from "react-scroll";
import t1 from '../../assets/lottie/guru.json'
import Lottie from "react-lottie";

export default function BlockchainSuccess() {
  const [lottiePause, setLottiePause] = useState<boolean>(true)

  const top = {
    loop: false,
    autoplay: false,
    animationData: t1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  const lottieRef: any = useRef(null)
  const playLottieOne = () => lottieRef?.current?.animation?.play()

  const stopMovie = (e: any) => {
    e.target.pause();
    console.log('off');
  }

  const playMovie = (e: any) => {
    e.target.play();
    console.log('on');
  }
  return (
    <>
      <Box className="bckchn_sccss_main_bx">
        <Box className="banner_ani">
          <Box className="lottie_space"
            onMouseEnter={(e) => {
              playLottieOne()
              setLottiePause(false)
            }}>
            <Lottie options={top}
              ref={lottieRef}
              isPaused={lottiePause}
              isStopped={lottiePause}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => {
                    setLottiePause(true)
                  },
                }
              ]}
            ></Lottie>
          </Box>
          <Image src="/img/banner_img.png" alt='' className="banner_img" />
        </Box>
        <Container>
          <Box className="sltns_strtgs_hdng">
            <Box>
              <Heading as="h1" className="h_flex">
                Harness THE
                <span className="blckchn_txt powr_mrg"> POWER</span>
              </Heading>             
              <Heading as="h1" className="h_flex">
                OF <span className="blckchn_txt powr_mrg"> Blockchain & AI:</span>
              </Heading>
              <span className="sccss_txt for_max_widh"> Guided Strategies for Success.</span>
            </Box>
            
          </Box>
          <Button className="def_btn">
            <Link
              to="Contactus"
              activeClass='active'
              spy={true}
              smooth={true}
              offset={360}
              duration={500}
            >
              CONTACT US
            </Link>
          </Button>
        </Container>

      </Box>
    </>
  );
}
