import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchPostApi = async (query: string, body: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${BASE_URL}/${query}`,
      data: body,
    });

    if (response.status === 200 || response.status === 201) {
      const data = await response;
      return data;
    } else if (response.status === 400) {
      const data = await response;
      return data;
    } else if (response.status === 403) {
      return false;
    } else if (response.status === 500) {
      console.log("Sorry the server is not available right now please try later")
    }
  } catch (error) {
    console.log(error);
  }
}