import {
   Box,
   Image,
   Text, 
   SimpleGrid,
   GridItem,
   Heading,
   Container,

  } from '@chakra-ui/react'
import React from 'react'
import Marquee from "react-easy-marquee";

export default function Footer() {
  return (
    <>
      <Box className='footer'>
        <Box className='foot_up_marqe'>
          <Marquee width="100%" height="53px" reverse={true} duration={25000} className="marquee_bgrnd">
            <Text><span>Consultation / Strategy / Legal and Regulatory Compliance / Gamification / Tokenomics </span></Text>
            <Image src='/img/marquee_logo.svg' alt='' className='' />
            <Text><span>Consultation / Strategy / Legal and Regulatory Compliance / Gamification / Tokenomics </span></Text>
            <Image src='/img/marquee_logo.svg' alt='' className='' />  
            <Text><span>Consultation / Strategy / Legal and Regulatory Compliance / Gamification / Tokenomics </span></Text>
            <Image src='/img/marquee_logo.svg' alt='' className='' />  
            <Text><span>Consultation / Strategy / Legal and Regulatory Compliance / Gamification / Tokenomics </span></Text>
            <Image src='/img/marquee_logo.svg' alt='' className='' />    
          </Marquee>
        </Box>
        <Box className='foote_bg_pernt'>
          <Image src='/img/footer_bg_left_right.png' alt='' className='firs_img_set' />  
          <Image src='/img/footer_bg_left_right.png' alt='' className='second_img_set' />  
          <Container>
            <Box className='logo_and_text_box'>
              <Image src='/img/block_gur_foot_logo.svg' alt='' className='' />  
              <Text>Block Gurus is a division of Block Leader LLC</Text>
            </Box>
            <Box className='three_items_content'>
              <SimpleGrid columns={12} columnGap={0} rowGap={0}>
                <GridItem colSpan={[12, 12, 4, 4]}>
                  <Box className='first_content'>
                    <Heading as="h3">bitgraphix</Heading>
                    <a href='https://bitgraphix.io/' target="_blank">
                      <Image src='/img/bitgraphix_img_foot.svg' alt='' className='' /> 
                        <span>bitgraphix.io</span>
                      <Image src='/img/arrow_img_foot.svg' alt='' className='' />  
                    </a>
                  </Box>
                </GridItem>
                <GridItem colSpan={[12, 12, 4, 4]}>
                  <Box className='first_content'>
                    <Heading as="h3">Block leader</Heading>
                    <a href='https://www.blockleader.io/' target="_blank">
                      <Image src='/img/blockleader_img_foot.svg' alt='' className='' /> 
                        <span>blockleader.io</span>
                      <Image src='/img/arrow_img_foot.svg' alt='' className='' />  
                    </a>
                  </Box>
                </GridItem>
                <GridItem colSpan={[12, 12, 4, 4]}>
                  <Box className='first_content'>
                    <Heading as="h3">dev legion</Heading>
                    <a href='https://www.devlegion.io/' target="_blank">
                      <Image src='/img/devlegion_img_foot.svg' alt='' className='' /> 
                        <span>devlegion.io</span>
                      <Image src='/img/arrow_img_foot.svg' alt='' className='' />  
                    </a>
                  </Box>
                </GridItem>
              </SimpleGrid>
            </Box>
          </Container>
          
        </Box>
        <Box className='fotr_last_pera'>
          <Text>BlockGurus 2023. ALl Rights Reserved (c). Powered by <a href='to'>BITGRAPHIX</a> </Text>
        </Box>
      </Box>
    </>
  )
}
