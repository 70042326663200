import { Box } from '@chakra-ui/react'
import React from 'react'
import AvoidHiddenSc from './section/AvoidHiddenSc'
import BlockchainSuccess from './section/BlockchainSuccess'
import ContactUs from './section/ContactUs'
import Faq from './section/Faq'
import GoItAloneSec from './section/GoItAloneSec'
import GuruGuidance from './section/GuruGuidance'
import Roadmap from './section/Roadmap'
import StartNowSec from './section/StartNowSec'

export default function HomePage() {
  return (
    <>
      <BlockchainSuccess />
      <Box className='home_bg'>
        <GuruGuidance />
        {/* <Roadmap /> */}
        <AvoidHiddenSc />
        <GoItAloneSec />
        <StartNowSec />
        <Faq />
        <ContactUs />
      </Box>

    </>
  )
}
