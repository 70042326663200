import {
  Box,
  Container,
  Heading,
  Image,
  Text,
  Input,
  Select,
  Button
} from "@chakra-ui/react";
import React from 'react'
import { Element } from 'react-scroll';

export default function AvoidHiddenSc() {
  return (
    <>
      <Element name='Avoidsc'>
        <Box className='avoid_hdden_main'>
          <Container>
            <Box className="avoid_hdden_up_content">
              <Image src="/img/avoid_up_img01.png" alt='' className="left_img_aj" />
              <Heading as="h1">Unlock Your Potential with Precision Strategies</Heading>
              <Text className="unlock_fr_p">Our aim is to help you navigate the complex landscape of blockchain, leveraging the power of AI to avoid hidden traps and maximize your potential.</Text>
            </Box>
            <Box className="centr_content_prnt">
              <Image src="/img/avod_centr_bg.png" alt='' className="avod_centr_bg" />
              <Box className='avoid_video'>
                <video width="688px" height="725px" autoPlay muted loop className="video_pernt">
                  <source src="/img/vdcube_v5_3.webm" type="video/mp4"/>
                </video>
              </Box>
              <Box className="all_hed_pera_css_prnt">
                <Box className="roadmap_box">
                  <Heading className="def_heding" as="h4">Strategic Roadmap</Heading>
                  <Image src="/img/roadmap_img01.png" alt='' className="dip_none_pernt" />
                  <Text className="def_peragrp">Leverage an AI-backed, Guru-approved roadmap to expedite your progress.</Text>
                </Box>
                <Box className="avoid_legal_box">
                  <Heading className="def_heding" as="h4">Regulatory Compliance</Heading>
                  <Image src="/img/avoid_legal_img02.png" alt='' className="dip_none_pernt" />
                  <Text className="def_peragrp">Our legal team has substantial experience in financial regulation and compliance laws, ensuring your blockchain journey is within the bounds of the law.</Text>
                </Box>
                <Box className="increase_box">
                  <Heading className="def_heding" as="h4">Profitability Enhancement</Heading>
                  <Image src="/img/increase_img03.png" alt='' className="dip_none_pernt" />
                  <Text className="def_peragrp">Outpace competitors and secure a larger market share with our AI-infused strategy and gamification.</Text>
                </Box>
                <Box className="make_lasting_box">
                  <Heading className="def_heding" as="h4">Impact Generation</Heading>
                  <Image src="/img/make_img04.png" alt='' className="dip_none_pernt" />
                  <Text className="def_peragrp">Stand apart from the crowd, be exceptional, and make a lasting impact in the blockchain world. </Text>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Element>
    </>
  )
}


