import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  useColorModeValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-scroll";

export default function Header() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Box className="header">
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          borderBottom={1}
          align={"center"}
          className="innr_hdr"
        >
          <Flex
            // flex={{ base: 1, lg: "auto" }}
            display={{ base: "flex", lg: "none" }}
          >
            <IconButton
              className="tggl_btn"
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", lg: "start" }}
            justifyContent={"space-between"}
          >
            <Box as="a" href="/">
              <Image src="/img/logo.svg" className="logo" />
            </Box>
            <Flex
              display={{ base: "none", lg: "flex" }}
              alignItems={"center"}
              className="mrgn_lft_flex"
            >
              <Link 
                className="hdr_link"
                to="Guidenceaj" 
                activeClass='active'
                spy={true}
                smooth={true}
                offset={210}
                duration={500}
              >
                 guidance
              </Link>
              <Link 
                className="hdr_link"
                to="Avoidsc" 
                activeClass='active'
                spy={true}
                smooth={true}
                offset={-140}
                duration={500}
                >
                What we do
              </Link>
              <Link 
                className="hdr_link"
                to="Faq" 
                activeClass='active'
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
               >
                FAQs
              </Link>
              <Link 
               className="hdr_link"
               to="Contactus" 
                activeClass='active'
                spy={true}
                smooth={true}
                offset={360}
                duration={500}
               >
                Contact Us
              </Link>
            </Flex>
          </Flex>

          <Stack
            flex={{ base: 1, lg: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
            className="hdt_btn_stack"
          >
            <Link 
              to="Contactus" 
              activeClass='active'
              spy={true}
              smooth={true}
              offset={360}
              duration={500}
              // display={{ base: "none", lg: "inline-flex" }}
              
              className="def_btn"
            >
              Start now!
            </Link>
          </Stack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav />
        </Collapse>
      </Box>
    </>
  );
}

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      display={{ lg: "none" }}
      className="cllps_innr"
    >
      <Link 
        className="hdr_link"
        to="Guidenceaj" 
        activeClass='active'
        spy={true}
        smooth={true}
        offset={100}
        duration={500}
        >
        guidence
      </Link>
      <Link 
        to="Avoidsc" 
        activeClass='active'
        spy={true}
        smooth={true}
        offset={-140}
        duration={500}
        className="hdr_link">
        What we do
      </Link>
      <Link 
        to="Faq" 
        activeClass='active'
        spy={true}
        smooth={true}
        offset={0}
        duration={500} 
        className="hdr_link">
        FAQs
      </Link>
      <Link 
        to="Contactus" 
        activeClass='active'
        spy={true}
        smooth={true}
        offset={360}
        duration={500}
        className="hdr_link">
        Contact Us
      </Link>
      {/* {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))} */}
    </Stack>
  );
};

// const MobileNavItem = ({ label, children, href }: NavItem) => {
//   const { isOpen, onToggle } = useDisclosure();

//   return (
//     <Stack spacing={4} onClick={children && onToggle}>
//       <Flex
//         py={2}
//         as={Link}
//         href={href ?? "#"}
//         justify={"space-between"}
//         align={"center"}
//         _hover={{
//           textDecoration: "none",
//         }}
//       >
//         <Text
//           fontWeight={600}
//           color={useColorModeValue("gray.600", "gray.200")}
//         >
//           {label}
//         </Text>
//         {children && (
//           <Icon
//             as={ChevronDownIcon}
//             transition={"all .25s ease-in-out"}
//             transform={isOpen ? "rotate(180deg)" : ""}
//             w={6}
//             h={6}
//           />
//         )}
//       </Flex>

//       <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
//         <Stack
//           mt={2}
//           pl={4}
//           borderLeft={1}
//           borderStyle={"solid"}
//           borderColor={useColorModeValue("gray.200", "gray.700")}
//           align={"start"}
//         >
//           {children &&
//             children.map((child) => (
//               <Link key={child.label} py={2} href={child.href}>
//                 {child.label}
//               </Link>
//             ))}
//         </Stack>
//       </Collapse>
//     </Stack>
//   );
// };

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

// const NAV_ITEMS: Array<NavItem> = [
//   {
//     label: "Inspiration",
//     children: [
//       {
//         label: "Explore Design Work",
//         subLabel: "Trending Design to inspire you",
//         href: "#",
//       },
//       {
//         label: "New & Noteworthy",
//         subLabel: "Up-and-coming Designers",
//         href: "#",
//       },
//     ],
//   },
//   {
//     label: "Find Work",
//     children: [
//       {
//         label: "Job Board",
//         subLabel: "Find your dream design job",
//         href: "#",
//       },
//       {
//         label: "Freelance Projects",
//         subLabel: "An exclusive list for contract work",
//         href: "#",
//       },
//     ],
//   },
//   {
//     label: "Learn Design",
//     href: "#",
//   },
//   {
//     label: "Hire Designers",
//     href: "#",
//   },
// ];
