import React from "react";
import { Box, Button, Container, Heading, Image, Text } from "@chakra-ui/react";
import { Link } from "react-scroll";
import t1 from "../../assets/lottie/flowers.json";
import Lottie from "react-lottie";

export default function StartNowSec() {
  const top = {
    loop: true,
    autoplay: true,
    animationData: t1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Box className="startnow_main_bx">
        <Container>
          <Box className="strtnw_innr_bx">
            <Image
              src="/img/startnow_left_design.png"
              alt=""
              className="left_design"
            />
            <Box className="srtnw_white_bx">
              <Box className="strtnw_lotti_box">
                <Lottie options={top}></Lottie>
              </Box>
              <Heading as="h5">Your Journey Begins Here!</Heading>
              <Text className="the_jrny_txt">
              The journey of 1000 miles begins with one single step.
              </Text>
              <Box className="three_boxex">
                <Box className="same_box">
                  <Box className="yllw_grdnt_bx">
                    <Heading as="h2">1</Heading>
                  </Box>
                  <Text>
                  Schedule a free 15 minute discovery session consultation.
                  </Text>
                </Box>
                <Box className="same_box">
                  <Box className="yllw_grdnt_bx">
                    <Heading as="h2">2</Heading>
                  </Box>
                  <Text>
                    Our gurus will create a custom roadmap for your success.
                  </Text>
                </Box>
                <Box className="same_box pddng_dcrs">
                  <Box className="yllw_grdnt_bx">
                    <Heading as="h2">3</Heading>
                  </Box>
                  <Text>
                  Leverage the power of blockchain and AI to build a brighter future.
                  </Text>
                </Box>
              </Box>
              <Button className="strtnw_cntctus_btn def_btn">
                <Link
                  to="Contactus"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={360}
                  duration={500}
                >
                  Contact Us
                </Link>
              </Button>
            </Box>
            <Image
              src="/img/startnow_right_design.png"
              alt=""
              className="right_design"
            />
          </Box>
        </Container>
      </Box>
    </>
  );
}
