import React from 'react'
import t1 from '../../assets/lottie/guru_giance_bg.json'
import Lottie from "react-lottie";
import { Link } from "react-scroll";
import {
    Box,
    Button,
    Container,
    Heading,
    Image,
    Text
  } from "@chakra-ui/react";
  import { Element } from 'react-scroll';
  
export default function GuruGuidance() {
  const top = {
    loop: true,
    autoplay: true,
    animationData: t1,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const stopMovie = (e:any) => {
    e.target.pause();
    console.log('off');
  }
  
  const playMovie = (e:any) => {
    e.target.play();
    console.log('on');
  }
  return (
    <>
      <Element name='Guidenceaj'>
        <Box className="gr_gdnc_main_bx">
          <Box className='lott_postn'>
            <Box className="hm_bg_lotti_anmtns">
              <Lottie options={top}></Lottie>
            </Box>
            <Container>
                <Box className="innr_gdnc_bx">
                  <Box className="flex_bx">
                    <Box className="white_html_bx">
                      <Heading as="h5">Navigating Complexity with Expert Guidance</Heading>
                      <Text>In the unpredictable terrain of blockchain, minor missteps can lead to significant setbacks. Navigating complex regulatory frameworks and building robust strategies can be daunting. But with Block Gurus' expert guidance, your journey to blockchain success can be seamless and secure.</Text>
                      <Box className="btn_bx">
                        <Button className="def_btn">
                          <Link 
                          to="Contactus" 
                          activeClass='active'
                          spy={true}
                          smooth={true}
                          offset={360}
                          duration={500}
                          >
                            Contact Us
                          </Link></Button>
                      </Box>
                      <Image src="/img/white_bx_desing.svg" alt='' className="desing_img" />
                      {/* <Image src="/img/chakras_img.png" alt='' className="chakra_desing_img" /> */}
                      <video width="688px" height="725px" muted className="chakra_desing_img" onMouseOver={e => playMovie(e)}
          onMouseOut={e => stopMovie(e)}>
                        <source src="/img/3D_Gears_v2.webm" type="video/mp4"/>
                      </video>
                    </Box>
                    <Image src="/img/blue_desing__img.png" alt='' className="blue_desing_img" />
                  </Box>
                  <Text className="abslt_txt">Our mission is to ensure your ideas withstand two significant challenges: the dynamic world of regulatory compliance and the potential pitfalls of unstructured development plans. Utilizing AI-powered insights and deep expertise, our team provides focused, efficient mentoring for legal support and strategic planning.</Text>
                </Box>
            </Container>
          </Box>
        </Box>
      </Element>
    </>
  )
}
