import React from 'react'
import {
    Box,
    Container,
    Heading,
    Image,
    Text,
    Grid,
    GridItem
  } from "@chakra-ui/react";
  import { Element } from 'react-scroll';

export default function Faq() {
  return (
    <>
        <Element name='Faq'>
            <Box className="faq_main_bx">
                <Container>
                    <Box className="frame_hdng_bx">
                        <Image src="/img/faq_hdng_frame.png" alt='' />
                        <Heading as="h3">Guru F.a.q.s</Heading>
                    </Box>
                    <Box className="grid_bx">
                        <Grid templateColumns='repeat(3, 1fr)' gap={10} className="rspnsv_bx">
                            <GridItem className="grd_itm_bx">
                                <Box className="same_innr_faq">
                                    <Heading as="h5">What is block gurus?</Heading>
                                    <Text>Block Gurus is the strategy and legal division of Block Leader. Our team comprises lawyers and blockchain experts passionate about guiding businesses and entrepreneurs in using cutting-edge blockchain and AI technology to develop extraordinary products and services.</Text>
                                </Box>
                            </GridItem>
                            <GridItem className="grd_itm_bx">
                                <Box className="same_innr_faq">
                                    <Heading as="h5">What is Gamification?</Heading>
                                    <Text>Gamification is a strategic service we offer to increase user engagement and market expansion. We apply game mechanics and principles to non-gaming systems, creating a fun and immersive experience that encourages user activity.</Text>
                                </Box>
                            </GridItem>
                            <GridItem className="grd_itm_bx">
                                <Box className="same_innr_faq">
                                    <Heading as="h5">Experienced legal team?</Heading>
                                    <Text>Over 20 years of experience in compliance and fintech, and relationships with regulators in the US, and abroad. Expertise in risk assessment, AML systems,  and expert witness for litigation. We’re gurus with a vast library of legal cases.  </Text>
                                </Box>
                            </GridItem>
                            <GridItem className="grd_itm_bx">
                                <Box className="same_innr_faq">
                                    <Heading as="h5">What is Block Leader? </Heading>
                                    <Text>Block Leader is our parent company offering a comprehensive suite of services for blockchain development. The journey with Block Leader starts here at the Gurus strategy division, where we help you identify your development needs and map out the services you'll require for your voyage towards blockchain success.</Text>
                                </Box>
                            </GridItem>
                            <GridItem className="grd_itm_bx">
                                <Box className="same_innr_faq">
                                    <Heading as="h5">Who is your service for?</Heading>
                                    <Text>If you're looking to leverage blockchain and AI in your business, require smart contracts, need advice on tokenomics, web3 integration, all while staying legally compliant, then we're your go-to team!</Text>
                                </Box>
                            </GridItem>
                            <GridItem className="grd_itm_bx">
                                <Box className="same_innr_faq">
                                    <Heading as="h5">How does it work? </Heading>
                                    <Text>Schedule a call and we'll discuss your idea. If your project is viable and legal, we devise a roadmap, leveraging blockchain and AI, to transition you from concept to success swiftly and efficiently.</Text>
                                </Box>
                            </GridItem>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </Element>
    </>
  )
}
