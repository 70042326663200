import React from "react";
import './assets/css/App.scss';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import HomePage from "./pages/HomePage";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ChakraProvider>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/ourworkdetails" element={<OurWorkDetails />} /> */}
          </Routes>
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </ChakraProvider>
  );
}

export default App;
